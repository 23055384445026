import React, { useState } from "react"
import { Accordion, AccordionPanel, Box, Button, Grommet } from "grommet"
import { CaretDown, CaretUp } from "grommet-icons"
import faqData from "../data/faqData"
import faqStyles from "./faq.module.scss"

const noActiveIndex = []
const allActiveindex = []
const parsedFaqData = Object.entries(faqData).map(([faqKey, data]) => {
  allActiveindex.push(parseInt(faqKey))
  return data
})

const customAccordionTheme = {
  global: {
    focus: {
      border: {
        color: "primary"
      }
    }
  },
  button: {
    default: {
      color: "text",
      border: undefined,
      padding: {
        horizontal: "12px",
        vertical: "8px"
      }
    },
    active: {
      background: { color: "#e1eef7", opacity: 0.5 },
      color: "text"
    },
    disabled: {
      opacity: 0.4
    },
    hover: {
      background: { color: "#cce3f2" }
    }
  },
  accordion: {
    icons: {
      expand: () => <CaretUp size="small" />,
      collapse: () => <CaretDown size="small" />,
      color: "#a9abae"
    },
    border: {
      color: "#a9abae"
    }
  }
}

const accordionPanels = parsedFaqData.map(datum => {
  return (
    <AccordionPanel
      className={faqStyles.panelQuestion}
      label={datum.question}
      key={datum.question}
    >
      <Box className={faqStyles.panelAnswer} background="eee2dc">
        {datum.answer}
      </Box>
    </AccordionPanel>
  )
})

const FAQ = props => {
  const [activeIndex, setActiveIndex] = useState(noActiveIndex)

  return (
    <Grommet theme={customAccordionTheme}>
      <Box>
        <Box
          pad={("med", { bottom: "16px" })}
          flex
          justify="end"
          direction="row"
        >
          <Button
            label="Expand All"
            active
            onClick={() => {
              setActiveIndex(allActiveindex)
            }}
            disabled={activeIndex.length === 10}
            margin={{ right: "4px" }}
          />
          <Button
            label="Collapse All"
            active
            onClick={() => {
              setActiveIndex(noActiveIndex)
            }}
            disabled={activeIndex.length === 0}
          />
        </Box>
        <Box flex direction="row" justify="end"></Box>
        <Box>
          <Accordion
            multiple={props.multiple}
            activeIndex={activeIndex}
            onActive={indexArr => {
              setActiveIndex(indexArr)
            }}
            className={faqStyles.panel}
          >
            {accordionPanels}
          </Accordion>
        </Box>
      </Box>
    </Grommet>
  )
}

export default FAQ
