import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import FAQ from "../components/faq"
import Spinner from "../components/spinner"
import cbtSessionsStyles from "../pages/cbt-sessions.module.scss"

const FAQPage = () => {
  const faq = <FAQ multiple />
  const [faqComponentReady, setFaqComponentReady] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setFaqComponentReady(true)
    }, 200)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Layout isHome={false}>
      <Head
        title="FAQ"
        description="Common questions about Cognitive Behavioural Therapy (CBT), counselling, power dynamics, accessibility, radical CBT, and how therapy works."
      />
      <h2>FAQ</h2>
      {!faqComponentReady && <Spinner />}
      {faqComponentReady && faq}
      <div className={cbtSessionsStyles.footNote}>
        <a
          name="ardito-raballino-2011"
          href="https://doi.org/10.3389/fpsyg.2011.00270"
          target="_blank"
          rel="noreferrer noopener"
          className={cbtSessionsStyles.footNoteLink}
        >
          1. Ardito & Raballino, 2011 https://doi.org/10.3389/fpsyg.2011.00270
        </a>
        <a
          name="bachelor-1991"
          href="https://doi.org/10.1037/0033-3204.28.4.534"
          target="_blank"
          rel="noreferrer noopener"
          className={cbtSessionsStyles.footNoteLink}
        >
          2. Bachelor, 1991 https://doi.org/10.1037/0033-3204.28.4.534
        </a>
      </div>
    </Layout>
  )
}
export default FAQPage
