import React from "react"
import { Paragraph } from "grommet"
import Link from "../components/link"
import cbtSessionsStyles from "../pages/cbt-sessions.module.scss"

const paraMargin = {
  top: "small",
  right: "small",
  bottom: "small",
  left: "small"
}

const faqData = {
  0: {
    question: "How do I know if I need therapy?",
    answer: (
      <>
        <Paragraph fill margin={paraMargin}>
          Therapy can be useful to anyone who wants to improve the way they
          relate to themselves, others, and the world. This could take a
          multitude of forms. For example, It may be that you are missing out on
          opportunities due to fear of failure, you feel stuck in a
          relationship, job, or friendship group, or that whatever you do never
          seems to quieten the cruel self-critical voice in your head. There is
          no need to feel like you meet the criteria for any clinically
          recognised condition such as PTSD, Major Depression or Anxiety.
        </Paragraph>
        <Paragraph fill margin={paraMargin}>
          Some people come to therapy with clear goals, some come with a general
          sense that their life could be better in some way. Many find that
          their goals evolve as therapy progresses: they become more specific,
          more realistic or ambitious, and new goals appear as people develop
          new insights into what is possible.
        </Paragraph>
        <Paragraph fill margin={paraMargin}>
          If any of this is resonating with you, I suggest you{" "}
          <Link to="/contact-me/">Contact Me</Link> to talk about what brings
          you to seek therapy and how we could work together.
        </Paragraph>
      </>
    )
  },
  1: {
    question: "What would happen in the first session?",
    answer: (
      <Paragraph fill margin={paraMargin}>
        The first session is all about you feeling safe, comfortable, and
        listened to, and me feeling like I have an understanding of what brings
        you here, how you are now, and us agreeing where we want to go in
        therapy. The first session has more structure built into it that most
        other sessions. For example, in the first session I’ll ask you to fill a
        questionnaire about how your last week has been, and we will go over
        things like confidentiality and our working agreement. In subsequent
        sessions the time is more tailored to exactly what you want to bring to
        that particular session.
      </Paragraph>
    )
  },
  2: {
    question: "Why do you use CBT?  Isn’t it quite clinical and superficial?",
    answer: (
      <>
        <Paragraph fill margin={paraMargin}>
          Great question! It’s probably the one I’ve been asked most since I
          qualified as a CBT therapist and there is good reason to have this
          concern! Cognitive Behavioural Therapy (CBT), especially in the UK, is
          very often used in a format, such as within the NHS, which is very
          time-limited and there is lots of pressure to meet mass-scale
          quantitative measures. Unsurprisingly, in this context it is harder to
          create a warm and welcoming environment with time to be holistic and
          expansive with treatment.
        </Paragraph>
        <Paragraph fill margin={paraMargin}>
          However, once CBT is taken out of that time-pressured
          quantitatively-focused context, it can be a very accessible
          therapeutic method that facilitates greater awareness of our emotions,
          behaviours, thoughts, and how they interact. There is the option of
          exploring the past, the development of coping strategies, and many
          tools for exploring new goals and behaviours.
        </Paragraph>
        <Paragraph fill margin={paraMargin}>
          If you are interested in a more in-depth exploration of this issue,
          check out my <Link to="/blog/why-do-you-use-cbt/">blog article.</Link>
        </Paragraph>
      </>
    )
  },
  3: {
    question: "What exactly is the process for becoming a client of yours?",
    answer: (
      <Paragraph fill margin={paraMargin}>
        On the <Link to="/contact-me/">Contact</Link> page you can write to me
        either via email or the contact form expressing an interest in therapy
        and we can arrange a free confidential phone conversation of up to 15
        minutes. I use this conversation to find out what you are looking for in
        therapy and address any concerns you have. If we agree to work together
        we will arrange a first full session to be conducted over Zoom or
        another suitable online platform.
      </Paragraph>
    )
  },
  4: {
    question:
      "What if I don’t feel like I connect with you or that in some way the therapy is not working for me?",
    answer: (
      <>
        <Paragraph fill margin={paraMargin}>
          The most important factor for therapy to meet a client’s goals is the
          quality of the relationship between therapist and client.
          <sup>
            <a
              className={cbtSessionsStyles.citationSuperscript}
              href="#ardito-raballino-2011"
            >
              1
            </a>
          </sup>{" "}
          It has also been found that the client, rather than the therapist, is
          a better judge of that quality.
          <sup>
            <a
              className={cbtSessionsStyles.citationSuperscript}
              href="#bachelor-1991"
            >
              2
            </a>
          </sup>{" "}
          It is therefore essential you listen to your instincts and, if you are
          comfortable doing so, talk them over with people you trust or even the
          therapist if that feels OK. Ultimately, if it doesn’t feel like it is
          working, then it probably isn’t.
        </Paragraph>
        <Paragraph fill margin={paraMargin}>
          However, we must be careful not to mistake effort or some discomfort
          as signs of things not working. Therapy is not easy, but it should
          never feel punishing, humiliating or like you cannot express yourself.
        </Paragraph>
      </>
    )
  },
  5: {
    question:
      "How do you work with the inevitable power imbalance that exists between therapist and client?",
    answer: (
      <Paragraph fill margin={paraMargin}>
        Firstly by acknowledging that it inevitably exists, as it is a
        relationship between a paid professional and someone seeking help. It is
        my job to hold this power wisely and with accountability. I also aim to
        alleviate the power imbalance by encouraging clients to disagree with me
        when they want to, and by consistently checking in that they think we
        are following their goals, making it clear they are ultimately guiding
        the process.
      </Paragraph>
    )
  },
  6: {
    question: "Why don’t you expect everyone to pay the same hourly rate?",
    answer: (
      <Paragraph fill margin={paraMargin}>
        People come to therapy from all kinds of backgrounds and social classes,
        and I want to minimise the financial barrier to people working with me.
        To some people, £50 is small change, whilst to others it would mean
        cutting back on the weekly food shopping. I therefore ask those who can
        afford to pay £50, or more, to pay this to enable others with less
        financial privilege to access the help they need.
      </Paragraph>
    )
  },
  7: {
    question: "What is it like having therapy online instead of in person?",
    answer: (
      <>
        <Paragraph fill margin={paraMargin}>
          It has its pros and cons. It removes the time and cost of the commute
          to/from therapy and allows people to have therapy in a familiar
          environment. Some people really appreciate doing therapy from the
          comfort of their own home; others would welcome a change of scene.
          Connectivity issues online are very rare depending on your connection
          speed, but some people miss the physical face-to-face contact. In my
          experience, working remotely is not a significant barrier to forming a
          strong therapeutic bond and the same tools and structure of sessions
          can be used.
        </Paragraph>
        <Paragraph fill margin={paraMargin}>
          Furthermore, with the ongoing Covid-19 situation worldwide, online
          therapy is becoming more and more the first or even the only option
          for therapy.
        </Paragraph>
      </>
    )
  },
  8: {
    question:
      "What difference is there, if any, between counselling and therapy?",
    answer: (
      <>
        <Paragraph fill margin={paraMargin}>
          The simple answer is there is no important difference. I call what I
          do counselling and therapy, as do most counsellors.
        </Paragraph>
        <Paragraph fill margin={paraMargin}>
          The wider picture is that counselling is a type of (psycho)therapy. On
          the NHS website, counselling is defined as “talking therapy that
          involves a trained therapist listening to you and helping you find
          ways to deal with emotional issues”. As this definition highlights,
          there is generally a strong focus on talking. Other forms of mental
          health treatment like dance or art therapy, which involve less or no
          talking, are unlikely to be considered counselling, but they are
          obviously therapy. I googled the root of the word therapy and
          apparently it comes from the Greek for ‘healing’ or ‘curing’.
        </Paragraph>
        <Paragraph fill margin={paraMargin}>
          Also, confusingly, therapy seems to have become synonymous with
          psychotherapy. When someone affirms uncharitably to another “you need
          therapy!” we know they are probably not referring to chemotherapy or
          massage therapy!
        </Paragraph>
      </>
    )
  },
  9: {
    question:
      "How often would sessions with you be? And how long would it last?",
    answer: (
      <>
        <Paragraph fill margin={paraMargin}>
          I would normally suggest weekly sessions to begin with. Twice-weekly
          sessions may be appropriate in some circumstances, and as therapy
          progresses you may also want to change to fortnightly sessions, for
          example. Even once we have had an ending I am always open to discuss
          future one-off sessions or starting regular sessions again.
        </Paragraph>
        <Paragraph fill margin={paraMargin}>
          The length of time people see therapists varies wildly. Some people
          appear to get what they need from a few sessions, whilst most benefit
          from having more time. It depends on many things, for example: the
          severity of your difficulties, what your expectations are, and your
          commitment to working on relevant challenges between sessions.
        </Paragraph>
        <Paragraph fill margin={paraMargin}>
          The answer to both of these questions depends ultimately on your
          needs, and my job is to help avoid the creation of dependency on
          therapy, whilst also offering all of the support that you need.
        </Paragraph>
      </>
    )
  }
}

export default faqData
