import React, { useState } from "react"
import { Anchor } from "grommet"
import { navigate } from "gatsby"

const Link = ({ to, ...rest }) => {
  const blue = "#6699cc"
  const hoverBlue = "#687864"
  const [hoverColor, setHoverColor] = useState(blue)
  return (
    <Anchor
      style={{
        fontWeight: 300,
        color: hoverColor,
        textDecoration: "none"
      }}
      href={to}
      onClick={ev => {
        ev.preventDefault()
        navigate(to)
      }}
      onMouseOver={() => setHoverColor(hoverBlue)}
      onMouseLeave={() => setHoverColor(blue)}
      {...rest}
    />
  )
}

export default Link
